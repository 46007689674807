<template>
    <div>
        <ts-page-title
            :title="$t('loanDisbursement.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanDisbursement.pageTitle'),
                    active: true
                }
            ]"
        />
        <div class="demo-spin-article">
            <ts-panel>
                <ts-panel-wrapper
                    class="tw-flex tw-items-center tw-justify-between"
                >
                    <Button
                        type="info"
                        ghost
                        @click="$router.push({ name: 'loan-disbursement-create' })"
                    >
                        {{ $t('addNew') }}
                    </Button>
                    <div class="tw-flex tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('loanDisbursement.search')"
                            :style="width"
                            enter-button
                            @on-search="onSearch"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </ts-panel-wrapper>
                <Table
                    :columns="columns"
                    :data="resources"
                    size="small"
                    :loading="loading"
                    max-height="700"
                    ref="selection"
                    @on-select="onSelectCheck"
                    @on-select-cancel="onSelectUncheck"
                    @on-select-all="onSelectAllCheck"
                    @on-select-all-cancel="onSelectAllUncheck"
                    stripe
                >
                    <template slot-scope="{ row }" slot="branch">
                        {{ row.branch ? row.branch.branch_name_en : '' }}
                    </template>
                    <!-- <template slot-scope="{ row }" slot="photo">
                        <img
                            class="tw-rounded tw-h-8"
                            v-lazy="{
                                src: photo(row.customer),
                                error:
                                    row.customer.gender == 'Male'
                                        ? require('@/assets/staff-default-man.png')
                                        : require('@/assets/staff-default-women.png'),
                                loading: require('@/assets/Rolling-1s-200px.svg'),
                                preLoad: 1.3,
                                attempt: 1
                            }"
                        />
                    </template> -->
                    <template slot-scope="{ row }" slot="customer_code">
                        {{ row.customer ? row.customer.customer_code : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="customer_name">
                        {{ row.customer ? row.customer.customer_name_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="loan_amount">
                        <span class="tw-font-bold text-blue">{{
                        formatCurrencyWithCode(row.loan_amount, row.currency)
                    }}</span>
                    </template>
                    <template slot-scope="{ row }" slot="loan_type">
                        {{ row.loan_type ? row.loan_type.loan_type_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="co">
                        {{ row.co ? row.co.employee_name_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="is_finished">
                        <span
                            v-if="row.is_finished"
                            class="badge bg-success tw-mr-1 tw-capitalize"
                        >
                            Yes
                        </span>
                        <span
                            v-else
                            class="badge bg-secondary tw-mr-1 tw-capitalize"
                        >
                            No
                        </span>
                    </template>
                    <template slot-scope="{ row }" slot="finished_by">
                        {{
                            row.finished_by
                                ? row.finished_by.employee
                                    ? row.finished_by.employee.employee_name_en
                                    : row.finished_by.user_name
                                : ''
                        }}
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <a
                            href="#"
                            class="text-primary tw-mr-2"
                            @click.prevent="generatePDF(row)"
                            v-tooltip="$t('loanDisbursement.customerSchedule')"
                        >
                            <i class="far fa-file-pdf"></i>
                        </a>
                        <a
                            href="#"
                            class="text-green tw-mr-2"
                            @click.prevent="onExportExcel(row)"
                            v-tooltip="$t('excel')"
                            :disabled="row._switching"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._switching"
                            ></i>
                            <i class="far fa-file-excel" v-else></i>
                        </a>
                        <a
                            href="#"
                            class="text-danger tw-mr-1"
                            @click.prevent="onPreviewPdf(row, 1)"
                            v-tooltip="$t('pdf')"
                        >
                            <i class="far fa-file-pdf"></i>
                        </a>
                        <a
                            href="#"
                            class="text-info tw-mr-1"
                            @click.prevent="onView(row)"
                            v-tooltip="$t('view')"
                        >
                            <Icon type="ios-eye" size="20" />
                        </a>
                        <a
                            href="#"
                            class="text-primary ml-2"
                            @click.prevent="onEdit(row)"
                            v-tooltip="$t('edit')"
                        >
                            <Icon type="ios-create" size="20" />
                        </a>
                        <Poptip
                            :title="$t('are_you_sure_to_delete')"
                            :transfer="true"
                            width="240"
                            v-if="!row._deleting"
                            @on-popper-show="() => (model.deleted_reason = '')"
                        >
                            <a
                                class="ml-2 text-danger"
                                v-tooltip="$t('delete')"
                                :disabled="row._deleting"
                            >
                                <i
                                    class="fas fa-circle-notch fa-spin"
                                    v-if="row._deleting"
                                ></i>
                                <Icon type="ios-trash" size="20" v-else />
                            </a>
                            <div slot="content">
                                <label class="required">{{
                                    $t('journalEntry.reason')
                                }}</label>
                                <input
                                    v-model.trim="model.deleted_reason"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            errors.has('deleted_reason') ||
                                            $v.model.deleted_reason.$error
                                    }"
                                />
                                <span
                                    class="invalid-feedback"
                                    v-if="reasonErrors.length > 0"
                                    >{{ reasonErrors[0] }}</span
                                >
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('deleted_reason')"
                                >
                                    {{ errors.first('deleted_reason') }}
                                </div>
                                <div class="tw-mt-2 tw-text-right">
                                    <a
                                        class="ivu-btn ivu-btn-primary ivu-btn-small"
                                        @click.prevent="onDelete(row)"
                                    >
                                        <span>OK</span>
                                    </a>
                                </div>
                            </div>
                        </Poptip>
                        <a
                            v-else
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </template>
                </Table>
                <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
                    <div>
                        <ts-button
                            color="danger"
                            outline
                            class="tw-mr-2 btn-sm"
                            @click="() => [(pdf_view = true), (type = 2)]"
                            :disabled="loan_id_lists.length <= 0"
                        >
                            <i class="far fa-file-pdf"></i>
                            {{ $t('previewPdf') }} ({{ loan_id_lists.length }})
                            items</ts-button
                        >
                        <!-- <ts-button
                            color="success"
                            outline
                            class="tw-mr-2 btn-sm"
                            :disabled="loan_id_lists.length <= 0"
                            @click="onExportExcelList"
                            :waiting="exporting"
                        >
                            <i class="far fa-file-excel"></i>
                            {{ $t('exportExcel') }} ({{ loan_id_lists.length }})
                            items</ts-button
                        > -->
                    </div>
                    <ts-i-pagination
                        v-model="pagination"
                        @navigate="fetchData"
                    ></ts-i-pagination>
                </div>
            </ts-panel>
        </div>
        <Spin size="large" fix v-if="generating"> </Spin>
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            fullscreen
            :z-index="1022"
            :title="$t('loanDisbursement.pageTitle')"
        >
            <!-- <show-detail /> -->
        </Modal>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('branchName')
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('loanDisbursement.approveStatus')
                }}</label>
                <Select
                    v-model="approve_status_id"
                    multiple
                    :max-tag-count="1"
                    :filterable="true"
                    :filter-by-label="true"
                    :placeholder="$t('all')"
                >
                    <Option
                        v-for="item in approveStatus"
                        :value="item.id"
                        :key="item.id"
                        :label="item.status"
                    >
                        {{ item.status }}
                    </Option>
                </Select>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('loanDisbursement.disbursementDate')
                }}</label>
                <DatePicker
                    type="daterange"
                    v-model="dateRange"
                    placement="bottom-end"
                    :placeholder="$t('selectDateRange')"
                    style="width: 100%"
                    :transfer="true"
                    format="dd-MM-yyyy"
                    @on-change="onChangeDisbursementDate"
                ></DatePicker>
            </div>
        </Drawer>
        <pdf-preview
            v-model="pdf_view"
            :id-lists="loan_id_lists"
            :type="type"
            @cancel="value => close(value)"
        />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            filename="customer-repayment-schedule"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
            @beforeDownload="beforeGenerate()"
            @hasDownloaded="hasGenerated()"
        >
            <section slot="pdf-content">
                <CustomerRepaymentScheduleEN v-if="$root.$i18n.locale === 'en'" :loan="loan" />
                <CustomerRepaymentScheduleKH v-else :loan="loan" />
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { debounce, trim } from 'lodash'
import * as FileDownload from 'downloadjs'
import expandRow from './table-expand.vue'
import PdfPreview from './components/pdf-preview'
// import showDetail from "./show.vue";
import { required } from 'vuelidate/lib/validators'
import { Errors } from 'form-backend-validation'
import moment from 'moment'

import VueHtml2pdf from "vue-html2pdf";
import CustomerRepaymentScheduleEN from "./components/customer-repayment-schedule-en.vue";
import CustomerRepaymentScheduleKH from "./components/customer-repayment-schedule-kh.vue";

export default {
    name: 'loanDisbursementIndex',
    components: {
        PdfPreview,
        VueHtml2pdf,
        CustomerRepaymentScheduleEN,
        CustomerRepaymentScheduleKH
    },
    data () {
        return {
            loading: false,
            generating: false,
            width: 'width: 400px',
            waiting: false,
            exporting: false,
            showForm: false,
            visible: false,
            pdf_view: false,
            type: 1,
            branch_id: [],
            loan_id_lists: [],
            loan: {},
            approve_status_id: [],
            errors: new Errors(),
            dateRange: [
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY')
            ],
            model: {
                deleted_reason: ''
            },
            approveStatus: [
                {
                    id: 0,
                    status: 'Pending'
                },
                {
                    id: 1,
                    status: 'Partially approved'
                },
                {
                    id: 2,
                    status: 'Approved'
                },
                {
                    id: 3,
                    status: 'Rejected'
                }
            ]
        }
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ['model.deleted_reason']
    },
    computed: {
        ...mapState('creditOperation/loanDisbursement', [
            'resources',
            'pagination'
        ]),
        ...mapGetters(['formatCurrencyWithCode']),
        search: {
            get () {
                return this.$store.state.creditOperation.loanDisbursement.search
            },
            set (newValue) {
                this.$store.commit(
                    'creditOperation/loanDisbursement/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'creditOperation/loanDisbursement/RESET_CURRENT_PAGE'
                )
            }
        },
        reasonErrors () {
            const errors = []
            if (!this.$v.model.deleted_reason.$error) return errors
            !this.$v.model.deleted_reason.required && errors.push('Required')
            return errors
        },
        columns () {
            return [
                {
                    type: 'selection',
                    width: 55,
                    align: 'center'
                },
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    width: 20
                },
                {
                    title: this.$t('loanDisbursement.branch'),
                    slot: 'branch',
                    minWidth: 140
                },
                {
                    title: this.$t('loanDisbursement.loanNumber'),
                    key: 'loan_number',
                    minWidth: 140,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('loanDisbursement.voucherNumber'),
                    key: 'voucher_number',
                    minWidth: 130,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('loanDisbursement.disbursementDate'),
                    key: 'disbursement_date',
                    minWidth: 170,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('loanDisbursement.customerCode'),
                    slot: 'customer_code',
                    align: 'center',
                    minWidth: 130
                },
                {
                    title: this.$t('loanDisbursement.customerName'),
                    key: 'customer_name',
                    slot: 'customer_name',
                    minWidth: 150,
                    sortable: true
                },
                {
                    title: this.$t('loanDisbursement.loanAmount'),
                    key: 'loan_amount',
                    slot: 'loan_amount',
                    minWidth: 200,
                    align: 'right',
                },
                {
                    title: this.$t('loanDisbursement.loanType'),
                    key: 'loan_type',
                    slot: 'loan_type',
                    minWidth: 250
                },
                {
                    title: this.$t('loanDisbursement.co'),
                    key: 'co',
                    slot: 'co',
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t('loanDisbursement.finished'),
                    key: 'is_finished',
                    slot: 'is_finished',
                    minWidth: 110,
                    align: 'center',
                    filters: [
                        {
                            label: 'Yes',
                            value: true
                        },
                        {
                            label: 'No',
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                is_finished: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('loanDisbursement.finishedBy'),
                    key: 'finished_by',
                    slot: 'finished_by',
                    sortable: true,
                    minWidth: 150,
                    align: 'center'
                },
                {
                    title: this.$t('loanDisbursement.finishedDate'),
                    key: 'finished_date',
                    sortable: true,
                    minWidth: 150,
                    align: 'center'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 150
                }
            ]
        }
    },
    methods: {
        photo (record) {
            if (record.photo) {
                return record.photo
            }

            return record.gender == 'Male'
                ? require('@/assets/staff-default-man.png')
                : require('@/assets/staff-default-women.png')
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/loanDisbursement/fetch', {
					...attributes,
                    branch_id: this.branch_id,
                    approve_status_id: this.approve_status_id,
                    dateRange: this.dateRange,
                    search: this.search
				})
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onPreviewPdf (record, type) {
            this.handleSelectAll(false)
            this.loan_id_lists = []
            this.loan_id_lists.push(record.loan_id)
            this.type = type
            this.pdf_view = true
        },
        close (value) {
            this.pdf_view = value
            this.loan_id_lists = []
        },
        onExportExcelList () {
            this.exporting = true
            this.errors = new Errors()
            this.$store
                .dispatch('creditOperation/loanDisbursement/exportExcel', {
                    loan_id_lists: this.loan_id_lists
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.exporting = false
                    this.loan_id_lists = []
                    this.handleSelectAll(false)
                })
        },
        onExportExcel (record) {
            this.handleSelectAll(false)
            this.loan_id_lists = []
            this.loan_id_lists.push(record.loan_id)
            record._switching = true
            this.errors = new Errors()
            this.$store
                .dispatch('creditOperation/loanDisbursement/exportExcel', {
                    loan_id_lists: this.loan_id_lists
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    record._switching = false
                    this.loan_id_lists = []
                })
        },
        onChangeDisbursementDate (dateRange) {
            this.dateRange = dateRange
            this.fetchData()
        },
        onView (record) {
            this.$store.commit(
                'creditOperation/loanDisbursement/SET_EDIT_DATA',
                record
            )
            this.showForm = true
        },
        onEdit (record) {
            this.$store.commit(
                'creditOperation/loanDisbursement/SET_EDIT_DATA',
                record
            )
            this.$router.push({
                name: 'loan-disbursement-edit',
                params: { id: record.loan_id }
            })
        },
        onSelectCheck (record) {
            this.setLoanIdListSelected(record)
        },
        onSelectUncheck (record) {
            this.setLoanIdListSelected(record)
        },
        onSelectAllCheck (record) {
            this.setLoanIdListSelected(record)
        },
        onSelectAllUncheck (record) {
            this.setLoanIdListSelected(record)
        },
        handleSelectAll (status) {
            this.$refs.selection.selectAll(status)
        },
        setLoanIdListSelected (records) {
            this.loan_id_lists = []
            if (records.length > 0) {
                records.map(row => {
                    this.loan_id_lists.push(row.loan_id)
                })
            }
        },
        async onDelete (record) {
            this.$v.form.$touch()
            if (this.$v.form.$invalid) return

            record._deleting = true
            this.$store
                .dispatch('creditOperation/loanDisbursement/destroy', {
                    id: record.loan_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData()
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                    this.model.deleted_reason = ''
                })
        },
        beforeGenerate(){
            this.generating = true
        },
        hasGenerated(){
            this.generating = false
        },
        generatePDF(row) {
            this.loan = row
            this.$refs.html2Pdf.generatePdf();
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN DISBURSEMENT',
                desc: not.text
            })
        },
        onSearch(){
            if(!this.search) return
            this.fetchData()
        }
    },
    watch: {
        branch_id: debounce(function () {
            this.fetchData()
        }, 500),
        approve_status_id: debounce(function () {
            this.fetchData()
        }, 500)
    },
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/loanDisbursement/RESET_STATE')
        next()
    }
}
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
