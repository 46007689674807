<style scoped>
.expand-row {
    margin-bottom: 10px;
}
</style>
<template>
    <div>
        <Row class="expand-row">
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.interestRate') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue"
                    >{{ row.interest_rate }} %</span
                >
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.insuranceFee') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    formatCurrencyWithCode(row.insurance_fee, currency)
                }}</span>
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.adminFee') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    formatCurrencyWithCode(row.admin_fee, currency)
                }}</span>
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.monthlyFee') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    formatCurrencyWithCode(row.monthly_fee, currency)
                }}</span>
            </Col>
        </Row>
        <Row class="expand-row ">
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.rescueFee') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    formatCurrencyWithCode(row.rescue_fee, currency)
                }}</span>
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.repaymentMethod') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    row.repayment_method
                        ? row.repayment_method.method_name_en
                        : ''
                }}</span>
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.firstInstallmentDate') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue"
                    >《{{ row.first_installment_date }}》</span
                >
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.skipSunday') }}:
                </span>
                <span
                    v-if="row.is_skip_sunday"
                    class="badge bg-success tw-mr-1 tw-capitalize"
                >
                    Yes
                </span>
                <span v-else class="badge bg-secondary tw-mr-1 tw-capitalize">
                    No
                </span>
            </Col>
        </Row>
        <Row>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.approveStatus') }}:
                </span>
                <span
                    class="expand-value tw-ml-2"
                    :class="statusStyle(row.approve_status)"
                    >{{
                        row.approve_status
                            ? row.approve_status.approve_status
                            : ''
                    }}</span
                >
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.approvedBy') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    row.approved_by
                }}</span>
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.approvedDate') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    row.approved_date ? '《' + row.approved_date + '' : ''
                }}</span>
            </Col>
            <Col span="5">
                <span class="expand-key"
                    >{{ $t('loanDisbursement.approvedComment') }}:
                </span>
                <span class="expand-value tw-ml-2 tw-font-bold text-blue">{{
                    row.approved_comment
                }}</span>
            </Col>
        </Row>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        row: Object
    },
    computed: {
        ...mapGetters(['formatCurrencyWithCode']),
        resources () {
            return this.row
        },
        currency () {
            return this.row.currency
        },
        statusStyle: () => status => {
            let badge = ''

            switch (status.approve_status_id) {
                case 0:
                    badge = 'badge bg-info'
                    break
                case 1:
                    badge = 'badge bg-primary'
                    break
                case 2:
                    badge = 'badge bg-success'
                    break
                case 3:
                    badge = 'badge bg-default'
                    break
            }

            return badge
        }
    }
}
</script>
